function calculateHedgePrice(
  tieValue,
  price,
  delta,
  crossPrice,
  sideValue,
  decimalPlaces,
) {
  if (delta === 0) {
    console.error("Delta cannot be zero");
    return 0; // Avoid division by zero
  }
  let result;
  if (sideValue === "sell") {
    result = tieValue + (crossPrice - price) / delta;
  } else if (sideValue === "buy") {
    result = tieValue - (crossPrice - price) / delta;
  } else {
    result = (crossPrice - price) / delta;
  }

  return result?.toFixed(decimalPlaces); // Round to 3 decimal places
}
function calculateHedgeQuantity(quantity, delta) {
  if (delta === 0) {
    console.error("Delta cannot be zero");
    return 0; // Avoid division by zero
  }

  return Math.floor(quantity * Math.abs(delta) * 100);
}
function getDecimalPlaces(value) {
  // Convert the value to a string
  const valueStr = value?.toString();

  // Check if the string contains a decimal point
  if (valueStr?.includes(".")) {
    // Split the string on the decimal point and get the length of the fractional part
    const decimalPlaces = valueStr.split(".")[1].length;

    // Return the decimal places, but limit to a maximum of 4
    return Math.min(decimalPlaces, 4);
  }

  // If there is no decimal point, return 0
  return 0;
}

function getHedgeDirection({ side, delta, optionType, position }) {
  if (side === "buy") {
    if (delta === "positive") {
      if (optionType === "call") {
        return position === "buy" ? "Buy" : "Sell";
      } else if (optionType === "put") {
        return position === "buy" ? "Sell" : "Buy";
      }
    } else if (delta === "negative") {
      if (optionType === "call") {
        return position === "buy" ? "Sell" : "Buy";
      } else if (optionType === "put") {
        return position === "buy" ? "Buy" : "Sell";
      }
    }
  } else if (side === "sell") {
    if (delta === "positive") {
      if (optionType === "call") {
        return position === "buy" ? "Sell" : "Buy";
      } else if (optionType === "put") {
        return position === "buy" ? "Buy" : "Sell";
      }
    } else if (delta === "negative") {
      if (optionType === "call") {
        return position === "buy" ? "Buy" : "Sell";
      } else if (optionType === "put") {
        return position === "buy" ? "Sell" : "Buy";
      }
    }
  }

  // Return a default or error case if no condition is met
  return "Invalid input";
}

export function getHedgePriceString(
  order,
  strategyTable,
  crossPrice,
  sideValue,
  bookingTable,
) {
  const parsedHedgePrice =
    bookingTable.find((row) => row.id?.includes("hedge_price"))?.exec1 || null;
  const decimalPlaces = getDecimalPlaces(parsedHedgePrice);
  const tieValue = order.tie ? Number(order.tie) : 0;
  const price = Number(strategyTable[0]?.price) || 0;
  const delta = Number(strategyTable[0]?.delta) || 1; // Default to 1 to avoid division by zero
  const crossPriceValue = crossPrice ? Number(crossPrice) : 0;

  // Calculate hedge price
  const hedgePrice = calculateHedgePrice(
    tieValue,
    price,
    delta,
    crossPriceValue,
    sideValue,
    decimalPlaces,
  );

  // Determine the color based on equality
  const color = parsedHedgePrice === hedgePrice ? "#8FB289" : "#FF6E57";

  // Construct the string with a span for coloring
  return (
    <span>
      Hedge Price = {tieValue}{" "}
      {sideValue === "sell" ? "+" : sideValue === "buy" ? "-" : "side is N/A"} (
      {crossPriceValue} - {price}) / {delta} ={" "}
      <span style={{ color }}>
        {" "}
        {hedgePrice} ({parsedHedgePrice})
      </span>
    </span>
  );
}

// Function to construct the hedge quantity string
export function getHedgeQuantityString(strategyTable, bookingTable) {
  const parsedHedgeQuantity =
    bookingTable.find((row) => row.id?.includes("hedge_quantity"))?.exec1 ||
    null;

  const quantity = Number(strategyTable[0]?.quantity) || 0; // Get the quantity, default to 0 if undefined
  const delta = Number(strategyTable[0]?.delta) || 1; // Get delta, default to 1 to avoid division by zero

  // Calculate hedge quantity
  const hedgeQuantity = calculateHedgeQuantity(quantity, delta);

  // Determine the color based on equality
  const color = parsedHedgeQuantity === hedgeQuantity ? "#8FB289" : "#FF6E57";

  // Construct the string with a span for coloring
  return (
    <span>
      Hedge Quantity = {quantity} * {Math.abs(delta) * 100} ={" "}
      <span style={{ color }}>
        {" "}
        {hedgeQuantity} ({parsedHedgeQuantity})
      </span>
    </span>
  );
}

export function getHedgeSide(
  strategyTable,
  sideValue,
  bookingTable,
  gridApiSource,
) {
  let drivingDelta,
    optionType,
    position,
    delta = null;
  const sideExec1 =
    bookingTable.find((row) => row.id?.includes("side"))?.exec1 || null;
  const side =
    sideExec1 && sideExec1?.toLowerCase() === "s"
      ? "sell"
      : sideExec1?.toLowerCase() === "b"
      ? "buy"
      : "n/a";

  const rowModel = gridApiSource.getModel();
  // Get all rows, including aggregated rows
  const allRows = rowModel.rowsToDisplay;
  // Iterate over all rows to find aggregated summary rows
  allRows.forEach((rowNode) => {
    if (!rowNode.group && !rowNode.aggData) {
      // This is an aggregated summary row
      const deltaByLeg = rowNode.data.DELTA_MID_RT;
      const quantity = rowNode.data.quantity;
      if (!drivingDelta) {
        drivingDelta = Math.abs(deltaByLeg * quantity);
        position = quantity >= 0 ? "buy" : "sell";
        optionType = rowNode.data.callPut?.toLowerCase();
        delta = deltaByLeg >= 0 ? "positive" : "negative";
      } else if (drivingDelta < Math.abs(deltaByLeg * quantity)) {
        drivingDelta = Math.abs(deltaByLeg * quantity);
        position = quantity >= 0 ? "buy" : "sell";
        optionType = rowNode.data.callPut?.toLowerCase();
        delta = deltaByLeg >= 0 ? "positive" : "negative";
      }
    }
  });
  const hedgeSide = getHedgeDirection({
    optionType,
    position,
    side,
    delta,
  });
  // Extract the delta value from the strategyTable
  // const delta = Number(strategyTable[0]?.delta);
  const parsedHedgeSide =
    bookingTable.find((row) => row.id?.includes("hedge_side"))?.exec1 || null;
  const convertedSide =
    parsedHedgeSide && parsedHedgeSide?.toLowerCase() === "b"
      ? "Buy"
      : parsedHedgeSide?.toLowerCase() === "s"
      ? "Sell"
      : null;

  // let hedgeSide;
  // // Determine the hedge side based on sideValue and delta
  // if (sideValue === "buy") {
  //   hedgeSide = delta >= 0 ? "Sell" : "Buy"; // Buy +ve -> Sell, Buy -ve -> Buy
  // } else if (sideValue === "sell") {
  //   hedgeSide = delta >= 0 ? "Buy" : "Sell"; // Sell +ve -> Buy, Sell -ve -> Sell
  // } else {
  //   hedgeSide = "Invalid Side Value"; // In case sideValue is not "Buy" or "Sell"
  // }

  const color = convertedSide === hedgeSide ? "#8FB289" : "#FF6E57";

  return (
    <span>
      Hedge Side –{" "}
      <span style={{ color }}>
        {hedgeSide} ({convertedSide})
      </span>
    </span>
  );
}
