import { Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./style";
import {
  getHedgePriceString,
  getHedgeQuantityString,
  getHedgeSide,
} from "./utils";

export const HedgeChecker = ({ order, parsedBookingText, gridApiSource }) => {
  const classes = useStyles();
  const [crossPrice, setCrossPrice] = useState(null);
  const [sideValue, setSideValue] = useState(null);
  const [hedgeQuantity, setHedgeQuantity] = useState(null);
  const [hedgePrice, setHedgePrice] = useState(null);
  const [hedgeSide, setHedgeSide] = useState(null);

  const { bookingTable, strategyTable } = useSelector(
    (state) => state.derivsPricerSlice,
  );

  useEffect(() => {
    if (bookingTable.length > 0) {
      const sideRow = bookingTable.find((row) => row.id === "side");
      setSideValue(
        sideRow.exec1 === "S" ? "sell" : sideRow.exec1 === "B" ? "buy" : null,
      );
    }
  }, [bookingTable]);
  useEffect(() => {
    if (strategyTable.length > 0 && crossPrice === null) {
      setCrossPrice(strategyTable[0]?.price);
    } else if (strategyTable.length === 0) {
      setCrossPrice(null);
    }
  }, [strategyTable]);

  useEffect(() => {
    if (sideValue) {
      setHedgeQuantity(getHedgeQuantityString(strategyTable, bookingTable));
      setHedgePrice(
        getHedgePriceString(
          order,
          strategyTable,
          crossPrice,
          sideValue,
          bookingTable,
        ),
      );
      setHedgeSide(
        getHedgeSide(strategyTable, sideValue, bookingTable, gridApiSource),
      );
    }
  }, [crossPrice, sideValue, strategyTable, order, bookingTable]);

  useEffect(() => {
    return () => {
      setCrossPrice(null);
      setSideValue(null);
      setHedgeQuantity(null);
      setHedgePrice(null);
      setHedgeSide(null);
    };
  }, []);

  return (
    <Grid className={classes.checkerContainer}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item className={classes.inputWrapper}>
          <Typography variant="h5" className={classes.label}>
            Cross Price
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter price"
            InputProps={{
              className: classes.input,
            }}
            fullWidth={false} // Remove full width to keep it smaller
            value={crossPrice}
            onChange={(e) => setCrossPrice(e.target.value)}
            type="number"
            className={classes.inputField} // Apply inputField class
          />
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item className={classes.inputWrapper}>
          <Typography variant="h5" className={classes.label}>
            {hedgeQuantity ? hedgeQuantity : null}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item className={classes.inputWrapper}>
          <Typography variant="h5" className={classes.label}>
            {hedgePrice ? hedgePrice : null}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item className={classes.inputWrapper}>
          <Typography variant="h5" className={classes.label}>
            {hedgeSide ? hedgeSide : null}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
